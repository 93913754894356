import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { Offer } from '@alao-frontend/core'

import { OfferCommonUtils } from '~/utils/offer/offer-common.utils'

@Component
export class OfferCardMixin extends Vue {
  @Prop({ required: true, default: () => ({}), type: Object })
  protected readonly offer!: Offer

  @Prop({ required: true, default: '', type: String })
  protected readonly url!: string

  protected offerCommonUtils = new OfferCommonUtils(this.offer.dto)

  get layoutBorderColor () {
    if (this.offer.expertTip) {
      return this.offer.expertTip.bgColor
    }

    return ''
  }

  get showAssistantToggle () {
    return this.$growthbook.isFeatureEnabled('show-plan-assistant')
  }

  @Emit('click')
  onClick () {
    return true
  }
}
